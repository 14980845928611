@font-face {
    font-family: 'Overpass Bold';
    src: local('Overpass Bold'), url(./static/overpass/Overpass-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Overpass Regular';
    src: local('Overpass Regular'), url(./static/overpass/Overpass-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Overpass SemiBold';
    src: local('Overpass SemiBold'), url(./static/overpass/Overpass-SemiBold.ttf) format('truetype');
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Overpass Regular';
    font-size: 14px;
    color: #49464E;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 812px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}